<template>
  <div class="vacancies">
    <!-- 1 -->
    <div class="title">Вакансии</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="vacancieslist">
      <div class="title">Открытые вакансии</div>
      <div class="list">
        <ul>
          <li @click="cero">{{ cerotitle }}</li>
          <li @click="uno">
            {{ unotitle }}
          </li>
          <li @click="dos">
            {{ dostitle }}
          </li>
          <li @click="tres">{{ trestitle }}</li>
          <li @click="cuatro">{{ quatrotitle }}</li>
          <li @click="cinco">{{ cincotitle }}</li>
        </ul>
      </div>
      <div class="subtitle">
        <div class="vacanciatitle">{{ title }}</div>
        <div class="vacanciaobyazanosti">Обязанности:</div>
        <div class="vacancialist">
          <ul>
            <li v-for="(item, index) in items" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="vacanciarules">Условия:</div>
        <div class="vacanciaruleslist">
          <ul>
            <li v-for="(item, index) in items2" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <button class="vacanciabtn">
          <a href="#div_form_send_resume">Подать резюме</a>
        </button>
      </div>
    </div>
    <!-- 4 -->
    <div id="div_form_send_resume" class="vacanciaform">
      <!-- <div class="titleform">Не нашли подходящую вакансию?</div> -->
      <div class="subtitleform">
        Оставьте свои данные и мы обязательно свяжемся <br />
        с вами.
      </div>
      <form class="form" @submit.prevent="sendEmailJS">
        <input
          class="input1"
          type="text"
          placeholder="ФИО"
          name="to_name"
          v-model="fio"
        />

        <input
          class="input2"
          type="text"
          placeholder="Желаемая должность"
          name="from_name"
          v-model="dolznost"
        />
        <textarea
          class="textarea1"
          placeholder="О себе (обязательно укажите телефон или e-mail для связи)"
          name="message"
          v-model="osebe"
        ></textarea>
        <label for="file-upload" class="custom-file-upload">
          Прикрепить файл (.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf)
        </label>
        <input
          class="inputfile"
          type="file"
          name="resume"
          id="file-upload"
          style="display: none"
          accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
        />
        <div
          v-if="validate == true"
          class="validateform"
          style="padding-top: 20px"
        >
          Необходимо заполнить все поля!
        </div>
        <div v-if="otpravleno == true" class="otpravleno">
          Мы рассмотрим вашу кандитатуру в ближайшее время и свяжемся с вами!
        </div>
        <input
          style="cursor: pointer; margin: 20px 0 0 0"
          class="btn"
          type="submit"
          value="ОТПРАВИТЬ"
        />
      </form>
      <img class="cowform" src="../assets/vacancies/cowform.png" alt="" />
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      finalRes: {},
      cerotitle: "",
      unotitle: "",
      dostitle: "",
      trestitle: "",
      quatrotitle: "",
      cincotitle: "",
      fio: "",
      dolznost: "",
      osebe: "",
      mail: "",
      phone: "",
      validate: false,
      otpravleno: false,
      title: "",
      items: [],
      items2: [],
    };
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      this.finalRes = await res.json();
      this.cerotitle = this.finalRes[26].text;
      this.unotitle = this.finalRes[29].text;
      this.dostitle = this.finalRes[32].text;
      this.trestitle = this.finalRes[35].text;
      this.quatrotitle = this.finalRes[38].text;
      this.cincotitle = this.finalRes[41].text;
    },
    previewfile(event) {
      this.fileData = event.target.files[0];
    },
    sendEmailJS(e) {
      if (this.fio != "" && this.dolznost != "" && this.osebe != "") {
        emailjs
          .sendForm(
            "pobeda",
            "pobeda_id",
            e.target,
            "user_r6zLonxJT1IJltE5wzNWp"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.status, result.text);
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
        this.fio = "";
        this.dolznost = "";
        this.phone = "";
        this.mail = "";
        this.osebe = "";
        this.validate = false;
        this.otpravleno = true;
      } else {
        this.validate = true;
      }
    },
    cero() {
      this.title = this.finalRes[26].text;
      this.items = this.finalRes[27].text.split(";");
      this.items2 = this.finalRes[28].text.split(";");
    },
    uno() {
      this.title = this.finalRes[29].text;
      this.items = this.finalRes[30].text.split(";");
      this.items2 = this.finalRes[31].text.split(";");
    },
    dos() {
      this.title = this.finalRes[32].text;
      this.items = this.finalRes[33].text.split(";");
      this.items2 = this.finalRes[34].text.split(";");
    },
    tres() {
      this.title = this.finalRes[35].text;
      this.items = this.finalRes[36].text.split(";");
      this.items2 = this.finalRes[37].text.split(";");
    },
    cuatro() {
      this.title = this.finalRes[38].text;
      this.items = this.finalRes[39].text.split(";");
      this.items2 = this.finalRes[40].text.split(";");
    },
    cinco() {
      this.title = this.finalRes[41].text;
      this.items = this.finalRes[42].text.split(";");
      this.items2 = this.finalRes[43].text.split(";");
    },
  },
  async mounted() {
    await this.getData();
    this.cero();
  },
};
</script>
<style lang="scss">
@media (min-width: 416px) {
  .vacancies {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/vacancies/bg.png");
      background-size: cover;
      height: 538px;
    }
    .vacancieslist {
      display: grid;
      grid-template-columns: minmax(auto, 533px) 1fr;
      grid-template-rows: minmax(218px, 218px) 1fr;
      .title {
        grid-row: 1;
        grid-column: 1 / span 2;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        line-height: 86%;
        text-transform: uppercase;
        align-self: center;
      }
      .list {
        grid-column: 1;
        grid-row: 2;
        font-size: 18px;
        line-height: 26px;
        font-family: Jost-Bold;
        ul li {
          list-style-type: none;
          padding: 30px 0 30px 130px;
          cursor: pointer;
        }
        ul li:hover {
          border: 1px solid;
        }
      }
      .subtitle {
        grid-column: 2;
        grid-row: 2;
        padding-top: 30px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, auto);
        row-gap: 30px;
        padding-left: 71px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        ul {
          padding-left: 35px;
        }
        .vacanciatitle .vacanciaobyazanosti .vacanciarules {
          font-family: Jost-Bold;
          font-size: 18px;
        }
        .vacanciabtn {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          text-transform: uppercase;
          background: linear-gradient(0deg, #bac8a5 0%, #e1edd0 100%);
          box-shadow: 0px 40px 60px rgba(184, 197, 166, 0.5);
          border-radius: 5px;
          width: 271px;
          height: 60px;
          border: none;
        }
      }
    }
    .vacanciaform {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, auto);
      margin: 120px 130px;
      row-gap: 20px;
      .titleform {
        grid-column: 1;
        grid-row: 1;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        font-family: Jost-Bold;
      }
      .subtitleform {
        grid-column: 1;
        grid-row: 2;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      .cowform {
        grid-column: 2;
        grid-row: 3;
        width: 406px;
        object-fit: cover;
        align-self: flex-start;
        justify-self: center;
      }
      .form {
        grid-column: 1;
        grid-row: 3;
        input {
          width: 575px;
          height: 68px;
          padding: 0 0 0 25px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }
        .input2 {
          margin: 24px 0;
        }
        textarea {
          width: 575px;
          height: 228px;
          resize: none;
          padding: 25px 0 0 25px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }
        .btn {
          background: linear-gradient(0deg, #bac8a5 0%, #e1edd0 100%);
          box-shadow: 0px 40px 60px rgba(184, 197, 166, 0.5);
          border-radius: 5px;
          width: 271px;
          height: 60px;
          border: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          text-transform: uppercase;
          padding-left: 0px;
        }
      }
    }
  }
}
@media (max-width: 415px) {
  .vacancies {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/vacancies/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .vacancieslist {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: minmax(218px, 218px) 1fr;
      justify-content: center;
      .title {
        grid-row: 1;
        grid-column: 1;
        font-weight: 500;
        font-size: 30px;
        line-height: 46px;
        text-align: left;
        text-transform: uppercase;
        align-self: center;
      }
      .list {
        grid-column: 1;
        grid-row: 2;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        text-decoration-line: underline;
        font-family: Jost-Bold;
        ul li {
          list-style-type: none;
          padding: 30px 0 30px 0;
        }
      }
      .subtitle {
        display: none;
      }
    }
    .vacanciaform {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(4, auto);
      margin: 120px 0;
      row-gap: 20px;
      justify-content: center;
      .titleform {
        grid-column: 1;
        grid-row: 1;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        font-family: Jost-Bold;
      }
      .subtitleform {
        grid-column: 1;
        grid-row: 2;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      .cowform {
        grid-column: 1;
        grid-row: 4;
        width: 335px;
        object-fit: cover;
      }
      .form {
        grid-column: 1;
        grid-row: 3;
        input {
          width: 310px;
          height: 68px;
          padding: 0 0 0 25px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }
        .input2 {
          margin: 24px 0;
        }
        textarea {
          width: 310px;
          height: 228px;
          resize: none;
          padding: 25px 0 0 25px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }
        .btn {
          background: linear-gradient(0deg, #bac8a5 0%, #e1edd0 100%);
          box-shadow: 0px 40px 60px rgba(184, 197, 166, 0.5);
          border-radius: 5px;
          width: 100%;
          height: 60px;
          border: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          text-transform: uppercase;
          padding-left: 0px;
        }
      }
    }
  }
}
</style>
